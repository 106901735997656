<template>
    <DnAccordion
        ref="accordion"
        :items="items"
        class="base-accordion"
        :scroll-smooth="true"
        :scroll-offset="scrollOffset"
    >
        <template #title="{ item }">
            <slot
                name="title"
                :item="item"
            >
                {{ item.title }}
            </slot>
        </template>

        <template #default="{ item }">
            <slot
                name="default"
                :item="item"
            >
                <div v-html="item.content" /> <!-- eslint-disable-line vue/no-v-html -->
            </slot>
        </template>

        <template #icon>
            <BaseIcon icon="chevron-down" />
        </template>
    </DnAccordion>
</template>

<script setup>
import { ref } from 'vue';
import DnAccordion from '@digitalnatives/accordion';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';

const accordion = ref(null);

defineProps({
    items: {
        type: Array,
        required: true,
        default() { return []; }
    },

    scrollOffset: {
        type: Number,
        default: 200,
    },
});
</script>

<style src="./BaseAccordion.less" lang="less"></style>
